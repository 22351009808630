<template>
  <div class="shop-dashboard shop-analytics">
    <div class="row">
      <div class="col-md-3">
        <b-card class="card-designs">
          <b-card-header>
            <b-card-title>
              {{ $t('ORDERS RECEIVED') }}
            </b-card-title>
          </b-card-header>
          <b-card-body class="pb-0">
            <h2>{{ storeDashboard.received_data }}</h2>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card class="card-designs">
          <b-card-header>
            <b-card-title>
              {{ $t('ORDERS DELIVERED') }}
            </b-card-title>
          </b-card-header>
          <b-card-body class="pb-0">
            <h2>{{ storeDashboard.delivered_data }}</h2>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card class="card-designs">
          <b-card-header>
            <b-card-title>
              {{ $t('TODAY EARNINGS') }}
            </b-card-title>
          </b-card-header>
          <b-card-body
            v-if="storeDashboard.today_earnings"
            class="pb-0"
          >
            <h2>{{ storeDashboard.today_earnings.total_amount ? storeDashboard.today_earnings.total_amount : 0 }} {{ storeDashboard.currency }}</h2>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card class="card-designs">
          <b-card-header>
            <b-card-title>
              {{ $t('TOTAL EARNINGS') }}
            </b-card-title>
          </b-card-header>
          <b-card-body
            v-if="storeDashboard.total_earnings"
            class="pb-0"
          >
            <h2>{{ storeDashboard.total_earnings.total_amount ? storeDashboard.total_earnings.total_amount : 0 }} {{ storeDashboard.currency }}</h2>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-12 mt-0">
        <div class="row mb-2">
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group :label="$t('From Date')">
              <b-form-datepicker
                v-model="dataInfo.fromDate"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group :label="$t('To Date')">
              <b-form-datepicker
                v-model="dataInfo.toDate"
                locale="en"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="my-auto"
          >
            <b-button
              variant="primary"
              @click="getStoreDashboard"
            >
              {{ $t('Search') }}
            </b-button>
          </b-col>
        </div>
        <highcharts
          class="reset-zoom"
          :options="chartOptions"
        />
      </div>
      <div
        v-if="storeDashboard.recent_data && storeDashboard.recent_data.length > 0"
        class="col-md-12 mt-3"
      >
        <h2>{{ $t('Recent Orders') }}</h2>
        <div class="table-responsive">
          <table class="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t('Order ID') }}</th>
                <th>{{ $t('Customer name') }}</th>
                <th>{{ $t('Ordered On') }}</th>
                <th>{{ $t('Status') }}</th>
                <th>{{ $t('Amount') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) of storeDashboard.recent_data"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.store_order_invoice_id }}</td>
                <td>{{ item.user ? (item.user.first_name + ' ' + item.user.last_name) : '' }}</td>
                <td>{{ item.created_time }}</td>
                <td :class="item.status === 'CANCELLED' ? 'text-danger' : item.status === 'COMPLETED' ? 'text-success' : 'text-info'">
                  {{ item.status }}
                </td>
                <td>{{ item.order_invoice ? storeDashboard.currency + item.order_invoice.total_amount : storeDashboard.currency + 0.0 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BCol, BFormGroup, BFormDatepicker, BButton } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { Chart } from 'highcharts-vue'
import moment from 'moment'
import { useStoreUi } from './useStore'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    highcharts: Chart,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      storeDashboard: {},
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      chartOptions: {
        series: [],
        title: '',
        xAxis: {
          categories: [],
        },
        credits: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: 'No. of Orders',
          },
        },
      },
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
  },
  mounted() {
    this.getStoreDashboard()
  },
  methods: {
    getStoreDashboard() {
      showLoader()
      const { getStoreOrders } = useStoreUi()
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`date_from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`date_to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      getStoreOrders(dataPayload.length > 0 ? `?${dataPayload.join('&')}` : '')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeDashboard = data.responseData
            this.chartOptions.xAxis.categories = new Array(12).fill(0).map((_, i) => moment(new Date(new Date().getFullYear(), i, 1)).format('MMMM'))
            this.chartOptions.series = [
              {
                name: 'Completed Orders',
                data: this.storeDashboard.completed_data,
              },
              {
                name: 'Cancelled Orders',
                data: this.storeDashboard.cancelled_data,
              },
            ]
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
.shop-analytics {
  .b-form-datepicker {
    .dropdown-menu {
      z-index: 999;
    }
  }
}
</style>
